.testimonial {
    display: flex;
    justify-content: center;
    align-items: center;

    figure.snip1390 {
        position: relative;
        overflow: hidden;
        text-align: center;
        font-size: 1rem;
        padding: 30px;
        background-image: linear-gradient(-25deg, rgba(0, 0, 0, 0.2) 0%, rgba(255, 255, 255, 0.1) 100%);
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-radius: 1rem;
    }

    figure.snip1390 *,
    figure.snip1390 *:before,
    figure.snip1390 *:after {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-transition: all 0.35s ease;
        transition: all 0.35s ease;
    }

    figure.snip1390 figcaption {
        width: 100%;
    }

    figure.snip1390 blockquote {
        margin: 0;
    }

    figure.snip1390 blockquote {
        font-size: 1rem;
        padding: 45px 20px 40px 50px;
        margin-top: 30px;
        background-color: #ffffff;
        border-radius: 5px;
        box-shadow: inset -1.4px -1.4px 2px rgba(0, 0, 0, 0.3);
        text-align: left;
        position: relative;
    }

    figure.snip1390 blockquote:before {
        font-family: 'FontAwesome';
        content: "\201C";
        position: absolute;
        font-size: 70px;
        opacity: 0.25;
        font-style: normal;
        top: 0px;
        left: 20px;
    }

    figure.snip1390 .profile {
        width: 100px;
        border-radius: 50%;
        display: inline-block;
        box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.4);
        margin-bottom: 10px;
        border: solid 5px #A6A57A;
    }
}

.testimonial-swiper.swiper {
    padding-bottom: 40px;
}